import qs from 'qs';
import { pathByLang } from '@/js/utils/lang.js';

$('.j-header-menu-toggle-button').on('click', function() {
    $('.j-header-block').toggleClass('toggled');
});

$('.j-footer-info-toggle-button').on('click', function() {
    $(this).closest('.forex-page-footer__info')
        .toggleClass('toggled')
        .find('.forex-page-footer__info-desc').stop().slideToggle(500);
});

$('.j-local-faq-q').on('click', function() {
    $(this).closest('.forex-local-faq__item')
        .toggleClass('toggled');
});

$('.j-toggle-account-mode-to-exchange').on('click', function(event) {
    event.preventDefault();

    const mode = $(this).attr('data-mode');

    axios.post('/toggle_account_mode', qs.stringify({ mode: mode })).then( res => {
        if (res.data && res.data.success) {
            const redirectUrl = (mode === 'exchange') ? '/balances' : '/forex';
            window.location.href = pathByLang(redirectUrl);
        }
    });
});
